<template>
  <PuzzleMain></PuzzleMain>
  <PuzzleServices></PuzzleServices>
  <PuzzlePartnership></PuzzlePartnership>
  <PuzzleCount v-if="viewportWidth >= 769"></PuzzleCount>
  <PuzzleAbout></PuzzleAbout>
  <PuzzleProject></PuzzleProject>
  <PuzzleCount v-if="viewportWidth <= 768"></PuzzleCount>
  <PuzzleQuestion></PuzzleQuestion>
  <PuzzleFooter></PuzzleFooter>
</template>

<script>
import PuzzleMain from "@/components/PazzleMain.vue";
import PuzzleCount from "@/components/PuzzleCount.vue";
import PuzzleQuestion from "@/components/PuzzleQuestion.vue";
import PuzzleFooter from "@/components/PuzzleFooter.vue";
import PuzzleServices from "@/components/PuzzleServices.vue";
import PuzzleAbout from "@/components/PuzzleAbout.vue";
import PuzzleProject from "@/components/PuzzleProject.vue";
import PuzzlePartnership from "@/components/PuzzlePartnership.vue";
export default {
  data() {
    return {
      viewportWidth: window.innerWidth,
    };
  },
  components: {
    PuzzleMain,
    PuzzleCount,
    PuzzleQuestion,
    PuzzleFooter,
    PuzzleServices,
    PuzzleAbout,
    PuzzleProject,
    PuzzlePartnership,
  },
};
</script>
