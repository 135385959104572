<template>
  <div class="projects" id="trust">
    <div class="container">
      <h4 class="projects-title">
        {{ projectTitle[activeLanguage] }}
      </h4>
      <div class="projects__content">
        <div class="projects__content-img">
          <img
            v-for="(item, index) in projectImg"
            :key="index"
            :src="require('@/assets/images/projects/trust/' + item.img)"
            alt=""
          />
        </div>
        <img
          class="projects__content-imgMob"
          src="../assets/images/projects/project.png"
          alt=""
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      projectTitle: {
        ru: "Нам доверяют",
        uz: `bizga ishonishadi `,
        en: "we are trusted",
      },
      projectImg: [
        {
          img: "1.webp",
        },
        {
          img: "2.webp",
        },
        {
          img: "3.webp",
        },
        {
          img: "4.webp",
        },
        {
          img: "5.webp",
        },
        {
          img: "6.webp",
        },
        {
          img: "7.webp",
        },
        {
          img: "8.webp",
        },
        {
          img: "9.webp",
        },
        {
          img: "10.webp",
        },
        {
          img: "11.webp",
        },
        {
          img: "12.webp",
        },
        {
          img: "13.webp",
        },
        {
          img: "14.webp",
        },
        {
          img: "15.webp",
        },
        {
          img: "16.webp",
        },
        {
          img: "17.webp",
        },
        {
          img: "18.webp",
        },
        {
          img: "19.webp",
        },
        {
          img: "20.webp",
        },
        {
          img: "21.webp",
        },
        {
          img: "22.webp",
        },
        {
          img: "23.webp",
        },
        {
          img: "24.webp",
        },
        {
          img: "25.webp",
        },
        {
          img: "26.webp",
        },
        {
          img: "27.webp",
        },
        {
          img: "28.webp",
        },
        {
          img: "29.webp",
        },
        {
          img: "30.webp",
        },
        {
          img: "31.png",
        },
        {
          img: "32.png",
        },
        {
          img: "33.png",
        },
        {
          img: "34.png",
        },
        {
          img: "35.png",
        },
        {
          img: "36.png",
        },
                {
          img: "37.png",
        },
                {
          img: "38.png",
        },
      ],
    };
  },
  computed: {
    activeLanguage() {
      return this.$store.getters.getLanguage;
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/grid/grid";
.projects {
  max-width: 1920px;
  width: 100%;
  margin: 0 auto;
  background: #f4f4f4;
  @include adaptivPaddinglg(0, 0, 70, 120, 0, 0, 35, 60);
  position: relative;
  @media (max-width: 768px) {
    @include adaptivPaddingmd(0, 0, 50, 50, 0, 0, 25, 25);
  }
  &-title {
    @include adaptiv-fontlg(36, 24);
    @include adaptiv-lineHeightlg(36, 24);
    font-family: "Montserrat-bold";
    color: #261d31;
    text-transform: uppercase;
    text-align: center;
    @media (max-width: 768px) {
      @include adaptiv-fontmd(36, 18);
      @include adaptiv-lineHeightmd(36, 18);
    }
  }
  &__content {
    width: 100%;
    display: flex;
    align-items: center;
    @include adaptivMarginlg(0, 0, 50, 0, 0, 0, 25, 0);
    @media (max-width: 768px) {
      overflow-y: hidden;
      overflow-x: scroll;
      &::-webkit-scrollbar {
        height: 3px;
      }
      &::-webkit-scrollbar-track {
        background: #eaeaea;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #3043b4;
        height: 3px;
        border-radius: 20px;
      }
    }
    &-img {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      @include adaptivGaplg(20, 10);
      cursor: pointer;
      @media (max-width: 768px) {
        display: none;
      }
      img {
        filter: brightness(0.9);
        mix-blend-mode: luminosity;
        transition: 0.5s;
        @media (max-width: 768px) {
          width: 400px;
          height: 100px;
        }
        &:hover {
          filter: none;
          mix-blend-mode: normal;
          transition: 0.5s;
        }
      }
    }
    &-imgMob {
      display: none;
      @media (max-width: 768px) {
        display: block;
        @include adaptivWidthmd(1000, 600);
        @include adaptivMarginmd(0, 0, 0, 30, 0, 0, 0, 15);
      }
    }
  }
}
</style>