<template>
  <div class="error">
    <div class="error_content">
      <h3 class="error_content-title">404</h3>
      <p class="error_content-descr">{{ errDesr[activeLanguage] }}</p>
      <span class="error_content-link"
        >{{ errDesrPage[activeLanguage] }}
        <router-link
          class="error_content-link-home"
          :to="{ name: 'home', params: { lang: activeLanguage } }"
          >{{ errToPage[activeLanguage] }}</router-link
        ></span
      >
    </div>
  </div>
</template>
  
  <script>
export default {
  data() {
    return {
      errDesr: {
        ru: "страница не найдена",
        uz: "sahifa topilmadi",
        en: "page not found",
      },
      errDesrPage: { ru: "Перейти на", uz: "asosiy", en: "go to" },
      errToPage: {
        ru: "главную страницу",
        uz: "sahifaga o'ting",
        en: "main page",
      },
    };
  },
  computed: {
    activeLanguage() {
      return this.$store.getters.getLanguage;
    },
  },
};
</script>
  
  <style lang="scss">
@import "@/assets/scss/grid/grid";
.error {
  width: 100%;
  height: 80vh;
  margin: 0 auto;
  background: linear-gradient(180deg, #2c4ab9 4.65%, #92147C 95.47%);
  overflow: hidden;
  &_content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @include adaptivPaddinglg(0, 0, 100, 0, 0, 0, 80, 0);
    @media (max-width: 768px) {
      @include adaptivPaddingmd(0, 0, 100, 100, 0, 0, 80, 80);
    }
    &-title {
      @include adaptiv-fontlg(250, 170);
      font-family: "Montserrat-extrabold";
      color: #fff;
      @media (max-width: 768px) {
        @include adaptiv-fontmd(300, 120);
      }
    }
    &-descr {
      @include adaptiv-fontlg(40, 20);
      font-family: "Montserrat-regular";
      color: #fff;
      @media (max-width: 768px) {
        @include adaptiv-fontmd(40, 15);
      }
    }
    &-link {
      @include adaptiv-fontlg(25, 15);
      font-family: "Montserrat-regular";
      color: #fff;
      margin-top: 20px;
      @media (max-width: 768px) {
        @include adaptiv-fontmd(30, 13);
      }
      &-home {
        color: #2fdcf6;
        text-decoration: underline;
      }
      img {
        @include adaptivWidthLg(500, 300);
        @include adaptivHeightlg(400, 200);
        mix-blend-mode: darken;
      }
    }
  }
}
</style>