<template>
  <div class="header">
    <video
      class="preview-video"
      autoplay="autoplay"
      :loop="true"
      playsinline
      src="@/assets/video/bg.mp4"
      muted="muted"
    ></video>
    <video
      class="preview-videoMob"
      autoplay="autoplay"
      :loop="true"
      playsinline
      src="@/assets/video/bgMob.mp4"
      muted="muted"
    ></video>
    <div class="container">
      <div class="header__content">
        <div class="header__content-info">
          <h1 class="header__content-info-title">
            {{ title[activeLanguage] }}
          </h1>
          <p
            class="header__content-info-descr"
            v-html="descr[activeLanguage]"
          ></p>
          <p
            class="header__content-info-descrMob"
            v-html="descrMob[activeLanguage]"
          ></p>
          <button class="header__content-info-btn" @click="openModal">
            {{ btn[activeLanguage] }}
            <font-awesome-icon
              class="icon"
              icon="fa-solid fa-circle-arrow-right"
            />
          </button>
        </div>
      </div>
    </div>
  </div>
  <PuzzleModal :show="show" @close="closeModal"></PuzzleModal>
</template>


<script>
import PuzzleModal from "./modal/PuzzleModal.vue";
export default {
  components: { PuzzleModal },
  data() {
    return {
      show: false,
      title: { ru: "PUZZLE", uz: `PUZZLE`, en: "PUZZLE" },
      descr: {
        ru: "<span>PUZZLE AGENCY</span> — эффективный и надёжный партнёр в поиске и формировании маркетинговой информации от потребителей до торговых объектов",
        uz: `<span>PUZZLE AGENCY</span> — iste'molchilardan savdo obyektlarigacha bo'lgan marketingga oid ma'lumotlarni qidirish hamda shakllantirishda samarali va ishonchli hamkor`,
        en: "<span>AGENCY</span> — is an effective and reliable partner in the search and formation of marketing information from consumers to retail outlets  ",
      },
      descrMob: {
        ru: "<span>AGENCY</span> — эффективный и надёжный партнёр в поиске и формировании маркетинговой информации ",
        uz: `<span>AGENCY</span>  — marketingga oid axborotni topish hamda shakllantirishda samarali va ishonchli hamkor`,
        en: "<span>AGENCY</span> — is an effective and reliable partner in the search and formation of marketing information from consumers to retail outlets",
      },
      btn: {
        ru: "Оставить заявку",
        uz: `So'rovnoma qoldirish`,
        en: "Leave an application",
      },
    };
  },

  methods: {
    goToTop() {
      window.scrollTo(0, 0);
    },
    openModal() {
      this.show = true;
    },
    closeModal() {
      this.show = false;
    },
  },
  computed: {
    activeLanguage() {
      return this.$store.getters.getLanguage;
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/grid/grid";
.header {
  width: 100%;
  // max-width: 1920px;
  margin: 0 auto;
  background: linear-gradient(174.14deg, #3034ac 4.65%, #92147c 95.47%);
  position: relative;
  overflow: hidden;
  @include adaptivHeightlg(800, 470);
  @media (max-width: 768px) {
    width: 100%;
    @include adaptivHeightmd(1000, 600);
  }
  .preview-video {
    width: 100%;
    min-height: 100%;
    object-fit: cover;
    filter: brightness(100%);
    user-select: none;
    @media (max-width: 768px) {
      display: none;
    }
    @media (min-width: 1921px) {
      position: fixed;
      height: 100vh;
    }
  }
  .preview-videoMob {
    display: none;
    @media (max-width: 768px) {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      filter: brightness(90%);
    }
  }
  &__content {
    width: 100%;
    display: flex;
    @media (max-width: 768px) {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &-info {
      display: flex;
      flex-direction: column;
      @include adaptivPaddinglg(0, 0, 180, 0, 0, 0, 120, 0);
      position: absolute;
      top: 0;
      @media (max-width: 768px) {
        width: 90%;
        @include adaptivPaddingmd(0, 0, 300, 0, 0, 0, 180, 0);
        justify-content: center;
        align-items: center;
      }
      &-title {
        @include adaptiv-fontlg(110, 70);
        @include adaptiv-lineHeightlg(134, 90);
        color: #fff;
        font-family: "Montserrat-extraBold";
        @media (max-width: 768px) {
          @include adaptiv-fontmd(115, 55);
          @include adaptiv-lineHeightmd(130, 67);
          @include adaptivMarginmd(0, 0, 0, 20, 0, 0, 0, 10);
        }
      }
      &-descr {
        width: 50%;
        @include adaptiv-fontlg(30, 16);
        @include adaptiv-lineHeightlg(40, 20);
        color: #fff;
        font-family: "Montserrat-light";
        span {
          font-family: "Montserrat-bold";
        }
        @media (max-width: 768px) {
          display: none;
        }
      }
      &-descrMob {
        @media (max-width: 768px) {
          display: block;
          @include adaptiv-fontmd(32, 14);
          @include adaptiv-lineHeightmd(39, 20);
          text-align: center;
          color: #fff;
          font-family: "Montserrat-light";
          span {
            font-family: "Montserrat-bold";
          }
        }
        @media (min-width: 769px) {
          display: none;
        }
      }
      &-btn {
        @include adaptivWidthLg(282, 170);
        border: 3px solid #2f2633;
        box-shadow: -8px 8px 0px 0px #2f2633;
        background: #fff;
        color: #2f2633;
        @include adaptiv-fontlg(20, 13);
        @include adaptiv-lineHeightlg(20, 13);
        font-family: "Montserrat-medium";
        @include adaptivPaddinglg(0, 0, 28, 28, 0, 0, 12, 12);
        @include adaptivMarginlg(0, 0, 30, 0, 0, 0, 15, 0);
        transition: 0.5s;
        &:hover {
          box-shadow: -8px 8px 0px 0px #2fdef6;
          transition: box-shadow 0.5s;
        }
        .icon {
          @include adaptiv-fontlg(16, 9);
          @include adaptiv-lineHeightlg(20, 13);
          color: #535a61;
          @media (max-width: 768px) {
            @include adaptiv-fontmd(18, 10);
          }
        }
        @media (max-width: 768px) {
          @include adaptivWidthmd(360, 180);
          width: 100%;
          @include adaptiv-fontmd(22, 14);
          @include adaptiv-lineHeightmd(22, 14);
          @include adaptivPaddingmd(0, 0, 30, 30, 0, 0, 15, 15);
          @include adaptivMarginmd(0, 0, 70, 0, 0, 0, 50, 0);
        }
      }
    }
  }
}
</style>