<template>
  <div class="service" id="services">
    <div class="container">
      <div class="service__content" ref="content">
        <div class="service__content_item">
          <div
            class="service__content_item-services"
            v-for="(item, index) in ServiceContent"
            :key="index"
            :class="{ active: item.isActive }"
            @click="serviseCard(index)"
          >
            <div class="line"></div>
            <span
              class="service__content_item-services-num"
              :class="{ active: item.isActive }"
              >{{ item.num }}</span
            >
            <h3 class="service__content_item-services-title">
              {{ item.title[activeLanguage] }}
            </h3>
            <p
              class="service__content_item-services-descr"
              v-html="item.descr[activeLanguage]"
            ></p>
          </div>
        </div>
        <div class="service__content_item-txt">
          <div
            class="service__content_item-txt-info"
            v-for="(item, index) in ServiceContent"
            :key="index"
            :class="{ active: item.isActive }"
          >
            <h3
              class="service__content_item-txt-info-title"
              v-html="item.infoTitle[activeLanguage]"
            ></h3>
            <p
              class="service__content_item-txt-info-descr"
              v-html="item.infoDescr[activeLanguage]"
            ></p>
          </div>
        </div>
        <button
          class="service__content_item-txt-btn"
          @click="nextServiseCard()"
        >
          {{ nextTitle[activeLanguage] }}
          <font-awesome-icon
            class="icon"
            icon="fa-solid fa-circle-arrow-right"
          />
        </button>
      </div>
      <swiper
        class="service__content-mob"
        modules
        @activeIndexChange="activeSlide"
        :pagination="{
          el: '.swiper-pagination',
          type: 'bullets',
          clickable: true,
        }"
        :breakpoints="{
          980: {
            slidesPerView: 4,
          },
          769: {
            slidesPerView: 3,
          },
          768: {
            slidesPerView: 1,
          },
        }"
      >
        <swiper-slide
          class="service__content-swiper"
          v-for="(item, index) in ServiceContent"
          :key="index"
        >
          <div class="service__content-swiper_item">
            <span
              class="service__content-swiper_item-num"
              :class="{ active: item.isActive }"
              >{{ item.num }}</span
            >
            <h3 class="service__content-swiper_item-title">
              {{ item.title[activeLanguage] }}
            </h3>
            <p
              class="service__content-swiper_item-descr"
              v-html="item.descr[activeLanguage]"
            ></p>
            <div class="line"></div>
            <p
              class="service__content-swiper_item-info-descr"
              v-html="item.infoDescr[activeLanguage]"
            ></p>
          </div>
          <h6 class="service__content-swiper_item-info-slide">
            {{ item.slide }}
          </h6>
        </swiper-slide>
        <div class="swiper-pagination"></div>
      </swiper>
      <img
        v-if="showSwipeIcon"
        class="swipe-icon"
        src="../assets/images/icons/swipe.png"
        alt=""
      />
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import SwiperCore, { Pagination, Navigation } from "swiper";
import "swiper/swiper-bundle.css";
SwiperCore.use([Pagination, Navigation]);
export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      activeIndex: 0,
      nextTitle: { ru: "далее", uz: "", en: "next" },
      ServiceContent: [
        {
          num: "1.",
          title: {
            ru: "Market Research ",
            uz: `Market Research `,
            en: "Market Research ",
          },
          descr: {
            ru: "(исследование рынка)",
            uz: `(bozor tadqiqoti)`,
            en: "",
          },
          infoTitle: {
            ru: "MARKET RESEARCH",
            uz: `MARKET RESEARCH`,
            en: "MARKET RESEARCH",
          },
          infoDescr: {
            ru: "<b>Мы собираем и анализируем данные о ситуации на рынке товаров.</b><br><br> <span>В процессе исследования собираем различную информацию, например, показатели по дистрибуции, цены на аналогичную продукцию у других производителей и продавцов, данные о ваших конкурентах, информацию про вашу целевую аудиторию и т. п.</span>",
            uz: `<b>Biz mahsulot bozoridagi holat to‘g‘risida ma'lumotlarni to‘playmiz va tahlil qilamiz. </b><br><br> <span>Tadqiqot jarayonida turli xildagi axborotni yig‘amiz, misol uchun, distributsiya bo'yicha ko'rsatkichlar, o‘xshash mahsulotlarning boshqa ishlab chiqaruvchi va sotuvchilardagi narxlari, raqiblaringiz to‘g‘risidagi ma'lumotlar, maqsadli auditoriyangiz to‘g‘risidagi axborot va h.k.</span>`,
            en: "<b>We collect and analyze data on the situation on the goods market.</b><br><br> <span>In the process of research, we collect various information, for example, distribution indicators, prices for similar products from other manufacturers and sellers, data about your competitors, information about your target audience, etc.</span>",
          },
          slide: "RETAIL AUDIT →",
          isActive: true,
        },
        {
          num: "2.",
          title: { ru: "RETAIL AUDIT", uz: `RETAIL AUDIT`, en: "RETAIL AUDIT" },
          descr: {
            ru: "(розничный аудит)",
            uz: `(chakana audit)`,
            en: "",
          },
          infoTitle: {
            ru: "RETAIL AUDIT",
            uz: `RETAIL AUDIT`,
            en: "RETAIL AUDIT",
          },
          infoDescr: {
            ru: "<b>Наше агенство успешно проводит розничный аудит.</b> <br><br> <span>Мы можем замерять как стандартные параметры по товарам, это замеры по ассортименту, представленности, долям по выкладке, объемам продаж, наличию рекламных материалов и т. п., так и по выставленным KPI для торговых сил, команд и дистрибьютеров.</span>",
            uz: `<b>Bizning agentligimiz muvaffaqqiyatli tarzda chakana audit o‘tkazadi.</b> <br><br> <span>Unda siz mahsulotlar bo‘yicha standart parametrlarni (assortiment, taniqlilik, ulush, savdo hajmi, reklama materiallarining mavjudligi bo‘yicha hisob-kitoblar), shuningdek, savdo kuchi, jamoasi va distribyutorlar uchun berilgan KPI kabilarni hisoblashimiz mumkin.</span>`,
            en: "<b>Our agency successfully conducts a retail audit.</b> <br><br> <span>In which we can measure both standard parameters for goods, these are measurements of the assortment, presentation, shares in the layout, sales volumes, availability of promotional materials, etc., and according to the set KPIs for trading forces, teams and distributors.</span>",
          },
          slide: "CENSUS →",
          isActive: false,
        },
        {
          num: "3.",
          title: { ru: "CENSUS", uz: `CENSUS`, en: "CENSUS" },
          descr: {
            ru: "(перепись)",
            uz: `(ro‘yxatga olish)`,
            en: "",
          },
          infoTitle: {
            ru: "CENSUS",
            uz: `CENSUS`,
            en: "CENSUS",
          },
          infoDescr: {
            ru: "<b>Мы проводим полную или частичную перепись торговых точек.</b> <br><br> <span>Для формирования потенциальной клиентской базы для развития вашей дистрибуции, продаж и маркетинговых мероприятий. <br><br> Основные параметры, которые вы получите по каждой точке: юридическое название, фактический адрес, GPS-координаты, фото фасада, тип и категория точки, категории товаров, которые продаются в точке, которые вам необходимы, и т. п.</span>",
            uz: `<b>Biz distribyutsiyangiz, savdolaringiz yoki marketing tadbirlaringizni rivojlantirish maqsadida istiqboldagi xaridorlar bazasini shakllantirish uchun savdo nuqtalarini to‘la yoki qisman hisob-kitob qilamiz.</b> <br><br> <span>Siz har bir nuqtadan oladigan asosiy parametrlar: yuridik nom, asl manzil, GPS-koordinatalar, old qism fotosurati, nuqtaning turi va kategoriyasi, nuqtada sotiladigan yoki Siz uchun zarur bo‘lgan mahsulotlar kategoriyasi va h.k.</span>`,
            en: "<b>We conduct a full or partial census of outlets to form a potential customer base for the development of your distribution, sales and marketing activities.</b> <br><br> <span>The main parameters that you will receive for each outlet: legal name, actual address, GPS coordinates, photo of the facade, type and category of the outlet, categories of goods that are sold at the outlet that you need, etc.</span>",
          },
          isActive: false,
        },
      ],
      showSwipeIcon: true,
    };
  },
  methods: {
    activeSlide(event) {
      if (event.activeIndex + 1 == event.slides.length) {
        this.showSwipeIcon = false;
      } else {
        this.showSwipeIcon = true;
      }
    },
    nextServiseCard() {
      const len = this.ServiceContent.length;
      if (this.activeIndex < len - 1) {
        this.serviseCard(this.activeIndex + 1);
      } else {
        this.serviseCard(0);
      }
    },
    serviseCard(index) {
      this.activeIndex = index;
      let toggle = false;
      if (this.ServiceContent[index].isActive == true) {
        return
      }
      this.ServiceContent.forEach((item, idx) => {
        if (index == idx && item.isActive) {
          toggle = true;
        }
        if (item.isActive) {
          item.isActive = false;
        }
      });
      if (!toggle) {
        this.ServiceContent[index].isActive = true;
      }
    },
  },
  computed: {
    activeLanguage() {
      return this.$store.getters.getLanguage;
    },
  },
  watch: {
    services() {
      this.ServiceContent = this.ServiceContent;
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/grid/grid";

.service {
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  background: linear-gradient(172.9deg, #8d167f 4.65%, #251c30 92.76%);
  @include adaptivPaddinglg(0, 0, 100, 100, 0, 0, 50, 50);
  overflow: hidden;
  position: relative;
  @media (max-width: 768px) {
    @include adaptivPaddingmd(30, 30, 70, 70, 20, 20, 35, 35);
    @include adaptivHeightmd(1020, 655);
    .container {
      width: 100%;
    }
  }
  &__content {
    width: 100%;
    @include adaptivHeightlg(650, 350);
    display: flex;
    justify-content: space-between;
    position: relative;
    @media (max-width: 768px) {
      display: none;
    }
    &_item {
      width: 50%;
      display: flex;
      flex-direction: column;
      border-right: 1px solid #fff;
      position: relative;
      @media (max-width: 768px) {
        width: 100%;
      }
      &:last-child {
        border: none;
        display: flex;
        justify-content: center;
      }
      &-services {
        width: 100%;
        @include adaptivHeightlg(180, 90);
        display: flex;
        flex-direction: column;
        justify-content: center;
        @include adaptivMarginlg(0, 0, 0, 50, 0, 0, 0, 30);
        position: relative;
        cursor: pointer;
        &:hover {
          .service__content_item-services-num,
          .service__content_item-services-title,
          .service__content_item-services-descr {
            transform: translateX(10px);
          }
        }
        &.active {
          .line {
            display: block;
          }
        }
        .line {
          display: none;
          @include adaptivWidthLg(6, 3);
          min-height: 100%;
          background: #2fdcf6;
          transition: 1s;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          margin-left: -30px;
          @media (max-width: 768px) {
            width: 3px;
          }
        }
        &:last-child {
          margin-bottom: 20px;
        }
        &-num {
          @include adaptiv-fontlg(55, 30);
          @include adaptiv-lineHeightlg(55, 35);
          color: #fff;
          font-family: "Montserrat-semibold";
          transform: translateX(0px);
          transition: 0.5s;
          @media (max-width: 768px) {
            @include adaptiv-fontmd(65, 33);
          }
          &.active {
            color: #2fdcf6;
          }
        }
        &-title {
          @include adaptiv-fontlg(25, 16);
          @include adaptiv-lineHeightlg(30, 21);
          color: #fff;
          font-family: "Montserrat-bold";
          text-transform: uppercase;
          transform: translateX(0px);
          transition: 0.5s;
          @media (max-width: 768px) {
            @include adaptiv-fontmd(32, 16);
          }
        }
        &-descr {
          @include adaptiv-fontlg(20, 12);
          @include adaptiv-lineHeightlg(40, 24);
          color: #fff;
          font-family: "Montserrat-light";
          transform: translateX(0px);
          transition: 0.5s;
          @media (max-width: 768px) {
            @include adaptiv-fontmd(20, 12);
          }
        }
      }
      &-txt {
        width: 50%;
        position: relative;
        @media (max-width: 768px) {
          width: 100%;
        }
        &-btn {
          position: absolute;
          right: 0;
          bottom: 0;
          margin-bottom: -5px;
          background: transparent;
          @include adaptiv-fontlg(18, 13);
          @include adaptiv-lineHeightlg(23, 18);
          color: #fff;
          font-family: "Montserrat-semibold";
          transition: 0.5s;
          .icon {
            @include adaptiv-fontlg(16, 11);
            margin-left: 5px;
          }
          &:hover {
            color: #2fdcf6;
            transition: 0.5s;
          }
        }
        &-info {
          flex-direction: column;
          justify-content: center;
          @include adaptivPaddinglg(120, 0, 0, 0, 60, 0, 0, 0);
          opacity: 0;
          overflow: hidden;
          position: absolute;
          top: 0;
          transform: translate(300px, 0);
          transition: transform 1s, opacity 0.5s;
          &.active {
            transition: transform 1s, opacity 1s;
            opacity: 1;
            transform: translate(0, 0);
            .service__content_item-txt-info-title {
              color: #2fdcf6;
              span {
                color: #fff;
              }
            }
          }
          &:last-child {
            .service__content_item-txt-info-descr {
              @include adaptivMarginlg(0, 0, 30, 0, 0, 0, 15, 0);
            }
          }
          &-title {
            @include adaptiv-fontlg(48, 24);
            @include adaptiv-lineHeightlg(60, 36);
            color: #fff;
            font-family: "Montserrat-semibold";
            display: flex;
            flex-direction: column;
            @include adaptivMarginlg(0, 0, 30, 0, 0, 0, 0, 0);
            @media (max-width: 768px) {
              @include adaptiv-fontmd(48, 24);
              @include adaptiv-lineHeightmd(60, 36);
            }
            span {
              @include adaptiv-fontlg(28, 14);
              font-family: "Montserrat-light";
            }
          }
          &-descr {
            @include adaptiv-fontlg(22, 13);
            @include adaptiv-lineHeightlg(40, 22);
            color: #fff;
            font-family: "Montserrat-bold";
            @include adaptivMarginlg(0, 0, 60, 0, 0, 0, 30, 0);
            span {
              font-family: "Montserrat-regular";
            }
            @media (max-width: 768px) {
              @include adaptiv-fontmd(20, 12);
              @include adaptiv-lineHeightmd(40, 23);
            }
          }
        }
      }
    }
  }
}
.service__content-mob {
  @media (min-width: 769px) {
    display: none;
  }
  @media (max-width: 768px) {
    display: block;
  }
  .service__content-swiper {
    display: block;
    width: 100%;
    @include adaptivHeightmd(800, 530);
    position: relative;
    &_item {
      width: 100%;
      display: flex;
      flex-direction: column;
      margin: 0 2px;
      position: relative;
      &-num {
        color: #2fdcf6;
        font-family: "Montserrat-semibold";
        @include adaptiv-fontmd(65, 33);
      }
      &-title {
        color: #2fdcf6;
        font-family: "Montserrat-bold";
        @include adaptiv-fontmd(40, 18);
        @include adaptiv-lineHeightmd(40, 18);
        text-transform: uppercase;
        @include adaptivMarginmd(0, 0, 30, 0, 0, 0, 15, 0);
      }
      &-descr {
        color: #fff;
        font-family: "Montserrat-light";
        @include adaptiv-fontmd(22, 12);
        @include adaptiv-lineHeightmd(40, 24);
      }
      .line {
        width: 100px;
        height: 3px;
        background: #2fdcf6;
        @include adaptivMarginmd(0, 0, 30, 30, 0, 0, 15, 15);
      }
      &-info {
        &-descr {
          color: #fff;
          font-family: "Montserrat-bold";
          @include adaptivMarginmd(0, 0, 20, 0, 0, 0, 10, 0);
          @include adaptiv-fontmd(28, 15);
          @include adaptiv-lineHeightmd(40, 22);
          span {
            @include adaptiv-fontmd(24, 13);
            @include adaptiv-lineHeightmd(30, 22);
            font-family: "Montserrat-regular";
          }
        }
        &-slide {
          position: absolute;
          bottom: 10px;
          right: 1%;
          @include adaptiv-fontmd(24, 12);
          @include adaptiv-lineHeightmd(40, 16);
          color: #fff;
          -webkit-background-clip: text;
          background-clip: text;
          font-family: "Montserrat-regular";
          animation: textAnimate 1500ms infinite ease-in-out;
        }
      }
    }
  }
  .swiper-slide,
  .swiper-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 5px;
  }
  .swiper-pagination {
    width: 100%;
    @include adaptivHeightmd(5, 3);
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: center;
    background: #fff;
    // margin-top: 150px;
  }
  .swiper-pagination-bullet {
    @include adaptivHeightmd(5, 3);
    width: 100%;
    border-radius: 0;
    margin: 0 !important;
    background: #2fdcf6;
  }
}
.swipe-icon {
  display: none;
  @media (max-width: 768px) {
    display: block;
    margin-left: auto;
    @include adaptivWidthmd(50, 25);
    @include adaptivHeightmd(50, 25);
    margin-top: 30px;
    filter: opacity(1);
    animation: swipeAnimate 2000ms infinite ease-in-out;
  }
}

@keyframes textAnimate {
  0% {
    color: #e0c3fc;
  }
  25% {
    color: #fff;
  }
  50% {
    color: #2fdcf6;
  }
  100% {
    color: #e0c3fc;
  }
}
@keyframes swipeAnimate {
  0% {
    filter: opacity(1);
  }
  50% {
    filter: opacity(0.5);
  }
  100% {
    filter: opacity(1);
  }
}
</style>