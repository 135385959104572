<template>
  <div class="question">
    <div class="container">
      <div class="question__content">
        <div class="question__content-box">
          <h3 class="question__content-box-title">
            {{ boxTitle[activeLanguage] }}
          </h3>
          <p
            class="question__content-box-descr"
            v-html="boxDescr[activeLanguage]"
          ></p>
        </div>
        <div class="question__content-form">
          <form
            id="reg-course"
            class="modal__form"
            @submit.prevent
            @submit="sendFormData"
          >
            <input
              type="text"
              :placeholder="placeholder[activeLanguage]"
              name="modal-name"
              class="form__input"
              required
              autocomplete="off"
              v-model="modalName"
            />
            <input
              type="tel"
              :placeholder="placeholder1[activeLanguage]"
              name="modal-tel"
              class="form__input hidden__phone"
              maxlength="15"
              v-model="modalPhone"
              @input="inputNumber"
              @focus="focusNumber"
              required
            />
            <input
              type="text"
              placeholder="Telegram @username"
              name="modal-name"
              class="form__input hidden__phone"
              v-model="modalUserName"
            />
            <button class="modal-btn" v-if="!submitted">
              {{ btn[activeLanguage] }}
            </button>
            <button class="modal-btn-accept" v-if="submitted">
              {{ accept[activeLanguage] }}
              <span><font-awesome-icon icon="fa-solid fa-check" /></span>
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      submitted: false,
      modalName: "",
      modalPhone: "",
      modalUserName: "",
      boxTitle: {
        ru: "у вас есть вопросы?",
        uz: "SAVOLLARINGIZ BORMI?",
        en: "you have questions?",
      },
      boxDescr: {
        ru: "Наш менеджер обработает <br> вашу заявку и свяжется с вами <br> в ближайшее время ",
        uz: "Bizning menedjerimiz sizning so'rovnomangizni o'rganib chiqadi va siz bilan tez orada bog'lanadi",
        en: "Our manager will process your application and contact you shortly",
      },
      placeholder: { ru: "Ваше имя", uz: `Ismingiz`, en: "Name" },
      placeholder1: {
        ru: "Номер телефона",
        uz: `Telefon raqamingiz`,
        en: "Phone number",
      },
      btn: {
        ru: "Оставить заявку",
        uz: `So'rovnoma yuborish`,
        en: "Send REQUEST",
      },
      accept: {
        ru: "ВАША ЗАЯВКА ПРИНЯТА",
        uz: `So'rovnoma qabul qilindi`,
        en: "Your application is accepted",
      },
    };
  },
  methods: {
    sendFormData() {
      if (
        this.modalName != "" &&
        this.modalPhone != "" &&
        this.modalPhone.length > 9
      ) {
        const formData = new FormData();
        formData.append("name", this.modalName);
        formData.append("username", this.modalUserName);
        formData.append("contact", this.modalPhone);
        this.$store.dispatch("sendForm", formData).then((response) => {
          this.submitted = true;
          this.modalName = "";
          this.modalPhone = "";
          this.modalUserName = "";
          setTimeout(() => {
            this.submitted = false;
            this.$emit("close");
          }, 2000);
        });
      }
    },
    inputNumber(event) {
      if (this.modalPhone.length < 2) this.modalPhone = "+";
      this.modalPhone = this.modalPhone.replace(/[^+|^\d|^\s]/, "");
      // const lengths = [4, 7, 11, 14];
      // lengths.forEach((item) => {
      //   if (this.modalPhone.length == item && event.data) {
      //     this.modalPhone += " ";
      //   }
      // });
    },
    focusNumber() {
      if (this.modalPhone == "") this.modalPhone = "+";
    },
    blurNumber() {
      if (this.modalPhone == "+998 ") this.modalPhone = "";
    },
    closeModal() {
      this.modalPhone = "";
      this.$emit("close");
    },
  },
  computed: {
    activeLanguage() {
      return this.$store.getters.getLanguage;
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/grid/grid";
.question {
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  background: #2b2135;
  @include adaptivPaddinglg(0, 0, 140, 70, 0, 0, 70, 35);
  position: relative;
  @media (max-width: 768px) {
    @include adaptivPaddingmd(0, 0, 80, 0, 0, 0, 40, 0);
  }
  &__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &-box {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      @include adaptivPaddinglg(0, 0, 45, 40, 0, 0, 23, 20);
      background: #403153;
      box-shadow: 0px 2px 40px rgba(0, 0, 0, 0.04);
      @media (max-width: 768px) {
        background: none;
      }
      &-title {
        @include adaptiv-fontlg(48, 24);
        @include adaptiv-lineHeightlg(53, 30);
        color: #fff;
        font-family: "Montserrat-semibold";
        text-transform: uppercase;
        @media (max-width: 768px) {
          @include adaptiv-fontmd(48, 16);
          @include adaptiv-lineHeightmd(60, 20);
        }
      }
      &-descr {
        @include adaptiv-fontlg(20, 16);
        @include adaptiv-lineHeightlg(24, 20);
        color: #fff;
        font-family: "Montserrat-light";
        br {
          display: none;
        }
        @media (max-width: 768px) {
          br {
            display: block;
          }
          color: #9d9d9d;
          font-family: "Montserrat-regular";
          @include adaptiv-fontmd(25, 13);
          @include adaptiv-lineHeightmd(30, 16);
          text-align: center;
          @include adaptivMarginmd(0, 0, 25, 30, 0, 0, 10, 10);
        }
      }
    }
    &-form {
      width: 100%;
      background: #322640;
      @include adaptivPaddinglg(50, 50, 100, 120, 25, 25, 50, 60);
      display: flex;
      flex-direction: column;
      justify-content: center;
      @media (max-width: 768px) {
        background: none;
        @include adaptivPaddingmd(0, 0, 0, 60, 0, 0, 0, 30);
      }
      .modal__form {
        display: flex;
        justify-content: center;
        @media (max-width: 768px) {
          width: 100%;
          flex-direction: column;
          align-items: center;
        }
      }
      .modal-btn {
        width: 100%;
        @include adaptivHeightlg(58, 30);
        background: linear-gradient(90deg, #4facfe 0%, #00f2fe 100%);
        color: #fff;
        @include adaptiv-fontlg(18, 11);
        @include adaptiv-lineHeightlg(15, 11);
        font-family: "Montserrat-regular";
        text-transform: uppercase;
        transition: background-color 1s;
        @include adaptivMarginlg(10, 10, 0, 0, 2, 2, 0, 0);
        &:hover {
          background: linear-gradient(90deg, #0084f7 0%, #2fdef6 100%);
          transition: background-color 1s;
        }
        .button-link {
          color: #303030;
        }
        @media (max-width: 768px) {
          width: 100%;
          @include adaptivHeightmd(80, 45);
          @include adaptiv-fontmd(22, 12);
          @include adaptiv-lineHeightlg(22, 19);
        }
        &-accept {
          width: 100%;
          @include adaptivHeightlg(58, 30);
          background: linear-gradient(90deg, #4facfe 0%, #00f2fe 100%);
          color: #fff;
          @include adaptiv-fontlg(18, 11);
          @include adaptiv-lineHeightlg(15, 11);
          font-family: "Montserrat-regular";
          text-transform: uppercase;
          transition: background-color 1s;
          @include adaptivMarginlg(10, 10, 0, 0, 2, 2, 0, 0);
          background: #7338d8;
          @include adaptiv-fontlg(15, 12);
          @media (max-width: 768px) {
            width: 100%;
            color: #fff;
            @include adaptivHeightmd(80, 45);
            @include adaptiv-fontmd(22, 12);
            @include adaptiv-lineHeightlg(22, 19);
          }
        }
      }
      .form__input {
        width: 100%;
        @include adaptivHeightlg(58, 30);
        display: block;
        font-family: "Montserrat-medium";
        font-style: normal;
        @include adaptiv-fontlg(18, 12);
        @include adaptiv-lineHeightlg(18, 12);
        @include adaptivMarginlg(10, 10, 0, 0, 2, 2, 0, 0);
        @include adaptivPaddinglg(25, 0, 0, 0, 10, 0, 0, 0);
        background: rgba(255, 255, 255, 0.3);
        color: #fff;
        border-radius: 0px;
        &::placeholder {
          color: rgba(255, 255, 255, 0.34);
          font-family: "Montserrat-regular";
          font-style: normal;
        }
        @media (max-width: 768px) {
          @include adaptiv-fontmd(22, 12);
          @include adaptiv-lineHeightmd(25, 15);
          @include adaptivHeightmd(75, 45);
          @include adaptivPaddingmd(15, 15, 0, 0, 10, 10, 0, 0);
          @include adaptivMarginmd(0, 0, 0, 30, 0, 0, 0, 20);
        }
      }
    }
  }
}
</style>