import { createStore } from 'vuex'
import languages from '@/store/languages'
import form from '@/store/modules/form'

export default createStore({
  state: {
    
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    languages,
    form
  }
})
