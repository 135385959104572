<template>
  <PuzzleNavigation />
  <router-view></router-view>
</template>


<script>
import PuzzleNavigation from "@/components/PuzzleNavigation";
export default {
  components: {
    PuzzleNavigation,
  },
};
</script>
<style lang="scss">
</style>
