const state = {
  activeLanguage: "ru",
  languages: ["ru", "uz", "en"],
};

const mutations = {
  changeLanguage(state, language) {
    state.activeLanguage = language;
  }
};

const actions = {
  selectLanguage(context, language) {
    context.commit("changeLanguage", language);
  }
};

const getters = {
  getLanguage(state) {
    return state.activeLanguage;
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
