<template>
  <div class="about" id="about-us">
    <div class="about__content">
      <img
        class="about__content-img"
        src="../assets/images/about/1.webp"
        alt=""
      />
      <div class="about__content-info">
        <p
          class="about__content-info-descr"
          v-html="aboutDescr[activeLanguage]"
        ></p>
        <img
          class="about__content-info-img"
          src="../assets/video/Map.gif"
          alt=""
        />
        <div class="about__content-info-box">
          <div class="about__content-info-box-first">
            <span class="about__content-info-box-first-name">{{
              aboutInfoName[activeLanguage]
            }}</span>
            <span class="about__content-info-box-first-info">{{
              aboutInfo[activeLanguage]
            }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      aboutDescr: {
        ru: "<span>В нашем быстром мире растёт потребность в надёжной информации для разработки и принятия уверенных и практических решений.</span> <br><br>Мы собрали свои лучшие <span>практики, знания и страсть</span>, чтобы найти, собрать и предоставить качественные данные, которые позволят найти чёткий и несложный путь к росту вашего бизнеса. <br><br> Для этого мы практикуем лучшие решения из <span>маркетинга и технологий</span>, успешно применяя при этом принципы безопасности, открытости, простоты и скорости. <br><br> Нашей задачей является получение результата, при котором наши клиенты могли бы действовать быстрее, умнее и смелее, а потребитель получил бы возможность повышать свой уровень жизни. <br><br> <span>Ведь ты действуешь лучше, когда уверен.</span>",
        uz: `<span>Bizning jadal rivojlanib borayotgan dunyomizda aniq va amaliy yechimlar qabul qilish uchun ishonchli axborotga bo’lgan ehtiyoj o‘sib bormoqda.</span> <br><br> Biz sifatli ma’lumotlarni topish, yig‘ish va taqdim etish uchun eng zo‘r bilim va ko‘nikmalarimiz, tajribamiz, kuchimizni bir joyga yig’dik, ular Sizga o‘z biznesingizni rivojlantirishda aniq va oson yo‘lni topishga yordam beradi.<br><br>Buning uchun biz marketing va texnologiya sohasidagi eng yetakchi yechimlarni amaliyotda qo‘llaymiz, bunda xavfsizlik, ochiqlik, oddiylik va tezlik tamoyillariga rioya qilamiz.<br><br>Bizning vazifamiz — mijozlarimiz tezroq, aqlliroq va dadilroq harakat qilishi, iste’molchi esa o’z hayot tarzini yaxshilashi uchun zaruriy natijaga erishishi sanaladi. <br><br> <span>Zero, o‘zingga ishonching komil bo‘lsa, harakatlaring ham dadilroq bo‘ladi.</span>`,
        en: `<span>In our fast-paced world, there is a growing need for reliable information to develop and make confident and practical decisions.</span> <br><br>We've put together our best practices, knowledge, and passion to find, collect, and deliver quality data to help you find a clear and easy path to growing your business.<br><br>To do this, we practice the best solutions from marketing and technology, while successfully applying the principles of security, openness, simplicity and speed.<br><br>Our goal is to achieve a result where our customers can act faster, smarter and bolder, and the consumer gets the opportunity to improve their standard of living.<br><br> <span>Cause you do better when you're confident.</span>`,
      },
      aboutInfoName: {
        ru: "Yuriy Li",
        uz: `Yuriy Li`,
        en: `Yuriy Li `,
      },
      aboutInfo: {
        ru: "Основатель и CEO «Puzzle Agency»",
        uz: `«Puzzle Agency» asoschisi va bosh direktori`,
        en: `Founder and CEO of «Puzzle Agency»`,
      },
    };
  },
  mounted() {},
  computed: {
    activeLanguage() {
      return this.$store.getters.getLanguage;
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/grid/grid";
.about {
  max-width: 1920px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  position: relative;
  &__content {
    width: 100%;
    display: flex;
    background: linear-gradient(180deg, #261D31 0%, #371C3E 100%);
    @media (max-width: 768px) {
      flex-direction: column;
    }
    &-info {
      width: 50%;
      display: flex;
      flex-direction: column;
      @include adaptivPaddinglg(150, 160, 100, 60, 75, 85, 50, 30);
      position: relative;
      @media (max-width: 768px) {
        width: 100%;
        @include adaptivPaddingmd(40, 40, 50, 60, 20, 20, 25, 45);
      }
      &-descr {
        background: linear-gradient(90deg, #e0c3fc 0%, #8ec5fc 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        @include adaptiv-fontlg(20, 13);
        @include adaptiv-lineHeightlg(24, 17);
        font-family: "Montserrat-medium";
        span {
          font-family: "Montserrat-extraBold";
        }
        @media (max-width: 768px) {
          @include adaptiv-fontmd(25, 14);
          @include adaptiv-lineHeightmd(35, 24);
        }
      }
      &-img {
        @include adaptivWidthLg(450, 200);
        @include adaptivMarginlg(0, 0, 30, 0, 0, 0, 15, 0);
        filter: brightness(0.9) opacity(0.9) invert(1);
        mix-blend-mode: screen;
        @media (max-width: 768px) {
          @include adaptivWidthmd(500, 260);
          position: absolute;
          bottom: 2%;
          right: 11%;
        }
      }
      &-box {
        width: 70%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: absolute;
        bottom: 15%;
        @media (max-width: 768px) {
          width: 100%;
          position: static;
          display: flex;
          @include adaptivMarginmd(0, 0, 60, 0, 0, 0, 30, 0);
        }
        &-first {
          display: flex;
          flex-direction: column;
          &-name {
            @include adaptiv-fontlg(20, 12);
            @include adaptiv-lineHeightlg(24, 16);
            font-family: "Montserrat-medium";
            color: #c995f9;
            @media (max-width: 768px) {
              @include adaptiv-fontmd(25, 14);
              @include adaptiv-lineHeightmd(35, 24);
            }
          }
          &-info {
            @include adaptiv-fontlg(20, 12);
            @include adaptiv-lineHeightlg(24, 16);
            font-family: "Montserrat-regular";
            color: #c6c6c6;
            @include adaptivMarginlg(0, 0, 10, 0, 0, 0, 5, 0);
            z-index: 1;
            @media (max-width: 768px) {
              @include adaptiv-fontmd(25, 14);
              @include adaptiv-lineHeightmd(35, 24);
            }
          }
        }
      }
    }
    &-img {
      width: 50%;
      height: 100%;
      object-fit: cover;
      @media (max-width: 768px) {
        width: 100%;
        position: static;
      }
    }
  }
}
</style>