import { createApp } from 'vue'
import App from './App.vue'
import '@/assets/scss/main.scss'
import router from '@/router'
import store from '@/store'
import VIntersection from '@/directives/VIntersection'
import FontAwesomeIcon from "@/fontawesome-icons";

createApp(App)
.directive('intersection', VIntersection)
.use(store)
.use(router)
.component('font-awesome-icon', FontAwesomeIcon)
.mount('#app-puzzle')
