const metas = {
  home: {
    ru: {
      title: "PUZZLE AGENCY | Исследование рынка и Retail аудит в Узбекистане",
      description: "Маркетинговое агенство - PUZZLE. Эффективный и надёжный партнёр в поиске и формировании маркетинговой информации от потребителей до торговых объектов",
      url: "https://puzzle.uz/",
      img: "",
      keywords: "",
    },
    uz: {
      title: "PUZZLE AGENCY | Bozor tadqiqoti va Chakana audit O'zbekistonda",
      description: "PUZZLE - marketing agentligi. Iste'molchilardan savdo obyektlarigacha bo'lgan marketingga oid ma'lumotlarni qidirish hamda shakllantirishda samarali va ishonchli hamkor",
      url: "https://puzzle.uz/uz",
      img: "",
      keywords: "",
    },
    en: {
      title: "PUZZLE AGENCY | Market Research, Retail Audit in Uzbekistan",
      description: "PUZZLE AGENCY — is an effective and reliable partner in the search and formation of marketing information from consumers to retail outlets ",
      url: "https://puzzle.uz/en",
      img: "",
      keywords: "",
    },
  },
};

function setMetas(to) {
  const lang = to.params?.lang == "" ? "ru" : to.params?.lang;
  if (metas[to.name]) {
    let meta;
    if (to.name == "/home") {
      meta = metas[to.name][to.params.id][lang];
    } else {
      meta = metas[to.name][lang];
    }

    document.title = meta.title;
    const metasTag = [...document.getElementsByTagName("meta")];
    metasTag.forEach((element) => {
      if (element.getAttribute("name") == "description") {
        element.setAttribute("content", meta.description);
      }
      if (element.getAttribute("name") == "keywords") {
        element.setAttribute("content", meta.keywords);
      }
      if (element.getAttribute("itemprop") == "description") {
        element.setAttribute("content", meta.description);
      }
      if (element.getAttribute("itemprop") == "url") {
        element.setAttribute("content", meta.url);
      }
      if (element.getAttribute("itemprop") == "name") {
        element.setAttribute("content", meta.title);
      }
      if (element.getAttribute("property") == "og:title") {
        element.setAttribute("content", meta.title);
      }
      if (element.getAttribute("property") == "og:description") {
        element.setAttribute("content", meta.description);
      }
      if (element.getAttribute("property") == "og:url") {
        element.setAttribute("content", meta.url);
      }
      if (element.getAttribute("property") == "og:image") {
        element.setAttribute("content", meta.img);
      }
    });
    const linksTag = [...document.getElementsByTagName("link")];
    linksTag.forEach((element) => {
      if (element.getAttribute("rel") == "canonical") {
        element.setAttribute("href", meta.url);
      }
    });
  }
}
export default setMetas;
