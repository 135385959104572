<template>
  <div class="partnership">
    <div class="partnership__content">
      <div class="partnership__content-info">
        <img
          class="partnership__content-info-logo"
          v-if="activeLanguage == 'ru'"
          src="../assets/images/about/bank.png"
          alt=""
        />
        <img
          class="partnership__content-info-logo"
          v-else
          src="../assets/images/about/bankEn.png"
          alt=""
        />
        <h3
          class="partnership__content-info-title"
          v-html="partnershipTitle[activeLanguage]"
        ></h3>
        <div class="partnership__content-info-line"></div>
        <p
          class="partnership__content-info-descr"
          v-html="partnershipDescr[activeLanguage]"
        ></p>
        <div
          class="partnership__content-info-chart"
          v-for="(item, index) in audit"
          :key="index"
        >
          <div class="partnership__content-info-chart-box">
            <h6 class="partnership__content-info-chart-title">
              {{ item.title[activeLanguage] }}
            </h6>
            <span class="partnership__content-info-chart-procent">
              {{ item.procent }}
            </span>
          </div>
          <div class="partnership__content-info-chart-line">
            <div class="line"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="partnership__content-info-box">
      <img
        class="partnership__content-info-box-img"
        src="../assets/images/about/partner.webp"
        alt=""
      />
    </div>
    <img
      class="partnership__content-imgMob"
      v-if="activeLanguage == 'ru'"
      src="../assets/images/about/evroRu.png"
      alt=""
    />
    <img
      class="partnership__content-imgMob"
      v-else
      src="../assets/images/about/evro.png"
      alt=""
    />
  </div>
</template>
  
  <script>
export default {
  data() {
    return {
      partnershipTitle: {
        ru: "Сотрудничество с Европейским Банком Реконструкции и Развития <span>(ЕБРР)</span>",
        uz: `Yevropa Rekonstruksiya va taraqqiyot banki bilan hamkorlik <span>(YRTB)</span>`,
        en: `Cooperation with the European Bank for Reconstruction and Development <span>(EBRD)</span>`,
      },
      partnershipDescr: {
        ru: "Мы постоянно интересуемся, учитывая наш опыт и знания, чем же ещё можем быть полезны в развитии бизнесов.<br><br> <span>В 2022 году мы договорились в сотрудничестве с ЕБРР, о запуске совместных проектов по консалтингу, чтобы помочь развивать различные проекты и бизнесы в управлении, разработке стратегий и бизнес-решений, продаж и маркетинга, помогаем найти механизмы и инструменты для их внедрения. </span> <br><br> Среди таких проектов - торговые компании, компании фармацевтического сектора и другие.",
        uz: `Tajribamiz va bilimimizni hisobga olgan holda, bizneslarni rivojlantirishda yana qanday yordam berishingiz mumkinligi to‘g‘risida qiziqamiz.<br><br> <span>2022-yilda YRTB bilan boshqaruv, strategiyalar va biznes-yechimlar ishlab chiqish, savdo va marketing sohasida turli loyiha va bizneslarni rivojlantrish uchun hamkorlik qilish, konsalting bo‘yicha turli qo‘shma loyihalarni ishlab chiqish to’g'risida shartnoma tuzdik, mexanizmlarni topish va ularni joriy etish uchun zaruriy jihozlarni topishda ko‘maklashmoqdamiz.</span> <br><br>Bunday loyihalar qatoriga savdo kompaniyalari, farmatsevtik sohaga oid kompaniyalar va boshqalar kiradi.`,
        en: `We are constantly interested, given our experience and knowledge, in what else we can be useful in business development.<br><br> <span>2022 year we agreed, in cooperation with the EBRD, to launch joint consulting projects to help develop various projects and businesses in management, strategy development and business solutions, sales and marketing, we help to find mechanisms and tools for their implementation.</span> <br><br>Among such projects are trading companies, companies in the pharmaceutical sector and others. `,
      },
      audit: [
        {
          title: { ru: "Retail audit", uz: "Retail audit", en: "Retail audit" },
          procent: "80%",
        },
        {
          title: { ru: "Research", uz: "Research", en: "Research" },
          procent: "90%",
        },
        {
          title: { ru: "Census", uz: "Census", en: "Census" },
          procent: "83%",
        },
      ],
      // partnerDescr: {
      //   ru: "Сотрудничество",
      //   uz: `hamkorlik`,
      //   en: `cooperation`,
      // },
    };
  },
  computed: {
    activeLanguage() {
      return this.$store.getters.getLanguage;
    },
  },
};
</script>
  
  <style lang="scss">
@import "@/assets/scss/grid/grid";
.partnership {
  max-width: 1920px;
  width: 100%;
  margin: 0 auto;
  position: relative;
  display: flex;
  @media (max-width: 768px) {
    flex-direction: column-reverse;
  }
  &__content {
    width: 100%;
    display: flex;
    background: #fff;
    position: relative;
    align-items: center;
    @media (max-width: 768px) {
      flex-direction: column;
    }
    &-info {
      width: 50%;
      display: flex;
      flex-direction: column;
      @include adaptivPaddinglg(150,160,50,60,75,85,25,30);
      position: relative;
      @media (max-width: 768px) {
        width: 100%;
        @include adaptivPaddingmd(40, 40, 50, 50, 20, 20, 25, 25);
      }
      &-logo {
        @include adaptivWidthLg(255, 180);
        @media (max-width: 768px) {
          display: none;
        }
      }
      &-title {
        color: #2c2c2c;
        @include adaptiv-fontlg(30, 15);
        @include adaptiv-lineHeightlg(45, 23);
        font-family: "Montserrat-light";
        @include adaptivMarginlg(0, 0, 24, 0, 0, 0, 15, 0);
        span {
          font-family: "Montserrat-bold";
        }
        @media (max-width: 768px) {
          @include adaptiv-fontmd(30, 16);
          @include adaptiv-lineHeightmd(45, 24);
          @include adaptivMarginmd(0, 0, 0, 40, 0, 0, 0, 20);
          font-family: "Montserrat-regular";
        }
      }
      &-line {
        width: 80px;
        height: 2px;
        background: #2c2c2c;
        @include adaptivMarginlg(0, 0, 40, 40, 0, 0, 20, 20);
        @media (max-width: 768px) {
          display: none;
        }
      }
      &-descr {
        width: 95%;
        color: #2c2c2c;
        @include adaptiv-fontlg(18, 12);
        @include adaptiv-lineHeightlg(25, 20);
        font-family: "Montserrat-light";
        @include adaptivMarginlg(0, 0, 0, 40, 0, 0, 0, 20);
        span {
          font-family: "Montserrat-bold";
        }
        @media (max-width: 768px) {
          @include adaptiv-fontmd(25, 14);
          @include adaptiv-lineHeightmd(35, 24);
          @include adaptivMarginlg(0, 0, 0, 90, 0, 0, 0, 50);
          span {
            font-family: "Montserrat-medium";
          }
        }
      }
      &-chart {
        display: flex;
        flex-direction: column;
        @include adaptivMarginlg(0, 0, 15, 15, 0, 0, 10, 10);
        &-box {
          display: flex;
          justify-content: space-between;
        }
        &-procent {
          color: #2c2c2c;
          @include adaptiv-fontlg(16, 12);
          @include adaptiv-lineHeightlg(20, 16);
          font-family: "Montserrat-bold";
          @media (max-width: 768px) {
            @include adaptiv-fontmd(18, 9);
            @include adaptiv-lineHeightmd(25, 16);
          }
        }
        &-title {
          color: #2c2c2c;
          @include adaptiv-fontlg(16, 12);
          @include adaptiv-lineHeightlg(20, 16);
          font-family: "Montserrat-bold";
          @include adaptivMarginlg(0, 0, 0, 20, 0, 0, 0, 10);
          @media (max-width: 768px) {
            @include adaptiv-fontmd(18, 9);
            @include adaptiv-lineHeightmd(25, 16);
          }
        }
        &-line {
          width: 100%;
          @include adaptivHeightlg(4, 3);
          background: #53596033;
          @media (max-width: 768px) {
            @include adaptivHeightmd(4, 3);
          }
          .line {
            height: 100%;
            background: #3334ab;
          }
        }
        &:nth-child(5) {
          .line {
            width: 80%;
          }
        }
        &:nth-child(6) {
          .line {
            width: 90%;
          }
        }
        &:nth-child(7) {
          .line {
            width: 83%;
          }
        }
      }
      &-box {
        width: 50%;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        @media(max-width: 768px){
          width: 100%;
        }
        &-img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          @media (max-width: 768px) {
            display: none;
          }
        }
      }
    }

    &-imgMob {
      display: none;
      @media (max-width: 768px) {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}
</style>