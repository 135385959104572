<template>
  <div class="footer" id="contacts">
    <div class="container">
      <div class="footer__content">
        <router-link
          class="footer__content-puzzle"
          :to="{ name: 'home', params: { lang: $route.params.lang } }"
          @click="goToTop()"
        >
          <img
            class="footer__content-puzzle-logo"
            src="@/assets/logo.png"
            alt="logo"
          />
          <p
            class="footer__content-puzzle-descr"
            v-html="puzzleDescr[activeLanguage]"
          ></p>
        </router-link>
        <div class="footer__content-contacts">
          <div class="footer__content-contacts-info">
            <h3
              class="footer__content-contacts-info-title"
              v-for="(item, index) in contactsInfo"
              :key="index"
              :id="item.id"
              @click="scrollToSection(item.id)"
            >
              {{ item.link[activeLanguage] }}
            </h3>
          </div>
          <div class="footer__content-contacts-number">
            <div class="footer__content-contacts-number-block">
              <font-awesome-icon
                class="footer__content-contacts-number-icon"
                icon="fa-solid fa-phone"
              />
              <a
                class="footer__content-contacts-number-link"
                href="tel: +998 90 980 53 00"
                >+998 90 980 53 00</a
              >
            </div>
            <div class="footer__content-contacts-number-block">
              <font-awesome-icon
                class="footer__content-contacts-number-icon"
                icon="fa-solid fa-envelope"
              />
              <a
                class="footer__content-contacts-number-link"
                href="mailto:hello@puzzle.uz "
                >hello@puzzle.uz</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="footer-company">
    <a href="https://centrismedia.uz/" class="footer-company-title" v-html="madeIn[activeLanguage]"></a>
  </div>
</template>

<script>
export default {
  data() {
    return {
      puzzleDescr: {
        ru: "Эффективный и надёжный партнер в поиске и формировании <br> маркетинговой информации от потребителей до торговых объектов.",
        uz: `Iste'molchilardan savdo obyektlarigacha bo'lgan marketingga oid <br> ma'lumotlarni qidirish hamda shakllantirishda samarali va ishonchli hamkor.`,
        en: "<span>PUZZLE AGENCY</span> is the most efficient and reliable partner in <br> the search for marketing <br> information.",
      },
      contactsInfo: [
        {
          link: { ru: "Наши услуги", uz: `Xizmatlar`, en: "Our services" },
          id: "#services",
        },
        {
          link: { ru: "O нас", uz: `biz haqimizda`, en: "About us" },
          id: "#about-us",
        },
        {
          link: {
            ru: "Нам доверяют",
            uz: `bizga ishonishadi`,
            en: "we are trusted",
          },
          id: "#trust",
        },
      ],
      madeIn: {
        ru: "сделано в <b>centris media</b>",
        uz: `<b>"centris media"</b>da tayyorlangan`,
        en: "made by <b>centris media</b>",
      },
    };
  },
  methods: {
    goToTop() {
      window.scrollTo(0, 0);
    },
    scrollTo(sectionId) {
      if (this.menuOpen) {
        this.menuOpen = false;
      }
      const section = document.querySelector(sectionId);
      const scrolDistance = section.offsetTop - 50;
      let distance = window.scrollY;
      const scroll = () => {
        if (scrolDistance > distance + 0.5 || scrolDistance < distance - 0.5) {
          distance -= (distance - scrolDistance) / 15;
          window.scrollTo(0, distance);
          this.timer = setTimeout(() => {
            scroll();
          }, 5);
        } else {
          window.scrollTo(0, scrolDistance);
          stop();
        }
      };
      const stop = () => {
        clearTimeout(this.timer);
        window.removeEventListener("wheel", stop);
      };
      stop();
      window.addEventListener("wheel", stop);
      scroll();
    },
    scrollToSection(sectionId) {
      if (this.$route.name != "home") {
        this.$router.push("/").then(() => {
          setTimeout(() => {
            this.scrollTo(sectionId);
          }, 300);
        });
      } else {
        this.scrollTo(sectionId);
      }
    },
  },
  computed: {
    activeLanguage() {
      return this.$store.getters.getLanguage;
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/grid/grid";
.footer {
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  background: #2a2135;
  @include adaptivPaddinglg(0, 0, 80, 80, 0, 0, 50, 50);
  position: relative;
  @media (max-width: 768px) {
    @include adaptivPaddingmd(0, 0, 50, 50, 0, 0, 40, 40);
  }
  &__content {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    @media (max-width: 768px) {
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    &-puzzle {
      width: 100%;
      @include adaptivHeightlg(200, 150);
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      @media (max-width: 768px) {
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
      }
      &-logo {
        @include adaptivWidthLg(130, 90);
        @include adaptivHeightlg(40, 30);
        @media (max-width: 768px) {
          @include adaptivWidthmd(200, 122);
          @include adaptivHeightmd(60, 40);
        }
      }
      &-descr {
        @include adaptiv-fontlg(16, 12);
        @include adaptiv-lineHeightlg(25, 18);
        color: #fff;
        font-family: "Montserrat-thin";
        text-align: center;
        span {
          font-family: "Montserrat-bold";
        }
        @media (max-width: 768px) {
          @include adaptiv-fontmd(24, 12);
          @include adaptiv-lineHeightmd(30, 18);
          @include adaptivMarginmd(0, 0, 40, 40, 0, 0, 20, 20);
          text-align: center;
          br{
            display: none;
          }
        }
      }
    }
    &-contacts {
      width: 40%;
      @include adaptivHeightlg(165, 120);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      @include adaptivMarginlg(0, 0, 30, 0, 0, 0, 15, 0);
      @media (max-width: 768px) {
        width: 100%;
        height: 100%;
        @include adaptivMarginmd(0, 0, 180, 0, 0, 0, 90, 0);
      }
      &-info {
        width: 100%;
        display: flex;
        justify-content: space-around;
        &-title {
          @include adaptiv-fontlg(14, 11);
          @include adaptiv-lineHeightlg(17, 14);
          color: #fff;
          font-family: "Montserrat-bold";
          text-transform: uppercase;
          @include adaptivMarginlg(0, 0, 10, 0, 0, 0, 5, 0);
          transition: 0.5s;
          cursor: pointer;
          &:hover {
            color: #dda864;
            transition: 0.5s;
          }
          @media (max-width: 768px) {
            @include adaptiv-fontmd(20, 10);
            @include adaptiv-lineHeightmd(30, 20);
            @include adaptivMarginmd(0, 0, 0, 35, 0, 0, 0, 20);
          }
        }
      }
      &-number {
        width: 60%;
        @include adaptiv-fontlg(18, 13);
        @include adaptiv-lineHeightlg(30, 20);
        color: #fff;
        font-family: "Montserrat-bold";
        border-top: 1px solid rgba(255, 255, 255, 0.719);
        @include adaptivHeightlg(95, 70);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        &-block {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          &:hover {
            .footer__content-contacts-number-link,
            .footer__content-contacts-number-icon {
              color: #dda864;
              transition: 0.5s;
            }
          }
          &:first-child {
            margin-top: 20px;
          }
          @media (max-width: 768px) {
            max-width: 100%;
            justify-content: center;
          }
        }
        &-icon {
          @include adaptiv-fontlg(18, 13);
          @include adaptiv-lineHeightlg(30, 20);
          @include adaptivMarginlg(0, 15, 0, 2, 0, 10, 0, 0);
          @media (max-width: 768px) {
            @include adaptiv-fontmd(20, 10);
            @include adaptiv-lineHeightmd(30, 20);
            @include adaptivMarginmd(0, 20, 0, 3, 0, 10, 0, 1);
          }
        }
        &-link {
          font-family: "Montserrat-light";
          color: #fff;
        }
        @media (max-width: 768px) {
          @include adaptiv-fontmd(20, 10);
          @include adaptiv-lineHeightmd(30, 20);
          @include adaptivMarginmd(0, 0, 0, 20, 0, 0, 0, 10);
          @include adaptivHeightmd(65, 35);
        }
      }
    }
  }
}
.footer-company {
  max-width: 1920px;
  width: 100%;
  margin: 0 auto;
  background: #1a1a1a;
  display: flex;
  align-items: center;
  justify-content: center;
  @include adaptivPaddinglg(0, 0, 25, 25, 0, 0, 15, 15);
  position: relative;
  @media (max-width: 768px) {
    @include adaptivPaddingmd(0, 0, 40, 40, 0, 0, 20, 20);
  }
  &-title {
    @include adaptiv-fontlg(12, 10);
    @include adaptiv-lineHeightlg(12, 10);
    color: #c6c6c6;
    font-family: "Montserrat-thin";
    cursor: pointer;
    @media (max-width: 768px) {
      @include adaptiv-fontmd(18, 10);
      @include adaptiv-lineHeightmd(23, 10);
    }
  }
}
</style>