<template>
  <div class="navigation">
    <div class="container">
      <div class="navigation__content">
        <router-link
          aria-label=""
          class="logo"
          title="main-page"
          :to="{ name: 'home', params: { lang: $route.params.lang } }"
          @click="goToTop()"
        >
          <img src="@/assets/logo.png" alt="logo" />
        </router-link>
        <div class="navigation__content_item" :class="{ show: mobileMenu }">
          <button
            v-for="(item, index) in NavLinks"
            :key="index"
            class="navigation__content_item-btn"
            :id="item.id"
            @click="scrollToSection(item.id)"
          >
            {{ item.link[activeLanguage] }}
          </button>
        </div>
        <div class="navigation-list-mobile" :class="{ active: menuOpen }">
          <div class="navigation-list-mobile-list">
            <button
              class="navigation-list-btn"
              v-for="(item, index) in NavLinks"
              :key="index"
              :id="item.id"
              @click="scrollToSection(item.id)"
            >
              {{ item.link[activeLanguage] }}
            </button>
          </div>
          <a
            href="tel: +998 90 980 53 00"
            class="navigation-list-mobile-list-link"
            ><font-awesome-icon
              class="navigation-list-mobile-list-link-icon"
              icon="fa-solid fa-phone"
            />
            &nbsp; +998 90 980 53 00
          </a>
        </div>
        <button
          class="menu-burger"
          :class="{ close: menuOpen }"
          @click="openMenu"
        >
          <span class="menu-burger-line"></span>
          <span class="menu-burger-line"></span>
          <span class="menu-burger-line"></span>
        </button>
        <div class="navigation__language">
          <ul class="navigation__content-num">
            <li class="navigation__content-language">
              <button
                class="ru choose"
                :class="{ active: activeLanguage == 'ru' }"
                @click="changeLanguage('ru')"
              >
                RUS
              </button>
            </li>
            <li class="navigation__content-language">
              <button
                class="en choose"
                :class="{ active: activeLanguage == 'en' }"
                @click="changeLanguage('en')"
              >
                ENG
              </button>
            </li>
            <li class="navigation__content-language">
              <button
                class="uz choose"
                :class="{ active: activeLanguage == 'uz' }"
                @click="changeLanguage('uz')"
              >
                UZB
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      mobileMenu: false,
      opened: false,
      mobileCourses: false,
      dropDownOpened: false,
      viewportWidth: window.innerWidth,
      menuOpen: false,
      choose: false,
      NavLinks: [
        {
          link: { ru: "Наши услуги", uz: `Xizmatlar`, en: "Our services" },
          id: "#services",
        },
        {
          link: { ru: "O нас", uz: `biz haqimizda`, en: "About us" },
          id: "#about-us",
        },
        {
          link: {
            ru: "Нам доверяют",
            uz: `bizga ishonishadi`,
            en: "we are trusted",
          },
          id: "#trust",
        },
        {
          link: { ru: "Контакты", uz: `Aloqa`, en: "Contacts" },
          id: "#contacts",
        },
      ],
    };
  },
  components: {},
  methods: {
    openMenu() {
      this.menuOpen = !this.menuOpen;
    },
    goToTop() {
      window.scrollTo(0, 0);
    },
    changeLanguage(language) {
      const lang = language == "ru" ? "" : language;
      this.$router.push({
        name: this.$route.name,
        params: { lang: lang },
      });
      const newLang = language == "ru" || language == "" ? "ru" : language;
      this.$store.dispatch("selectLanguage", newLang);
    },
    scrollTo(sectionId) {
      if (this.menuOpen) {
        this.menuOpen = false;
      }
      const section = document.querySelector(sectionId);
      const scrolDistance = section.offsetTop - 50;
      let distance = window.scrollY;
      const scroll = () => {
        if (scrolDistance > distance + 0.5 || scrolDistance < distance - 0.5) {
          distance -= (distance - scrolDistance) / 15;
          window.scrollTo(0, distance);
          this.timer = setTimeout(() => {
            scroll();
          }, 5);
        } else {
          window.scrollTo(0, scrolDistance);
          stop();
        }
      };
      const stop = () => {
        clearTimeout(this.timer);
        window.removeEventListener("wheel", stop);
      };
      stop();
      window.addEventListener("wheel", stop);
      scroll();
    },
    scrollToSection(sectionId) {
      if (this.$route.name != "home") {
        this.$router.push("/").then(() => {
          setTimeout(() => {
            this.scrollTo(sectionId);
          }, 300);
        });
      } else {
        this.scrollTo(sectionId);
      }
    },
  },
  computed: {
    activeLanguage() {
      return this.$store.getters.getLanguage;
    },
  },
  watch: {
    menuOpen() {
      if (this.menuOpen) {
        document.body.classList.add("block");
      } else {
        document.body.classList.remove("block");
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/grid/grid";

.block {
  overflow: hidden !important;
  -webkit-overflow-scrolling: touch !important;
}
.navigation {
  width: 100%;
  // max-width: 1920px;
  margin: 0 auto;
  background: #2c4ab9;
  position: sticky;
  top: 0;
  right: 0;
  left: 0;
  z-index: 10;
  @media (max-width: 768px) {
    @include adaptivPaddinglg(0, 0, 30, 30, 0, 0, 25, 25);
  }
  .logo {
    @include adaptivWidthLg(121, 90);
    @include adaptivHeightlg(45, 30);
    @media (max-width: 768px) {
      @include adaptivWidthmd(121, 80);
      @include adaptivHeightmd(35, 25);
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  &__content {
    display: flex;
    align-items: center;
    &_item {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: auto;
      margin-right: auto;
      @media (max-width: 768px) {
        display: none;
      }
      &-btn {
        background: none;
        @include adaptiv-fontlg(16, 13);
        @include adaptiv-lineHeightlg(22, 17);
        text-transform: uppercase;
        color: #fff;
        font-family: "Montserrat-bold";
        @include adaptivPaddinglg(40, 40, 25, 25, 20, 20, 20, 20);
        display: flex;
        align-items: center;
        justify-content: center;
        &:hover {
          border-bottom: 3px solid #fff;
          @include adaptiv-lineHeightlg(18, 13);
          @media (max-width: 768px) {
            border-bottom: none;
          }
        }
      }
    }
    &-num {
      display: flex;
      align-items: center;
      justify-content: center;
      .icon {
        color: #7e5cc5;
      }
      &-phone {
        @include adaptiv-fontlg(20, 14);
        @include adaptiv-lineHeightlg(24, 14);
        color: #fff;
        font-family: "Montserrat-light";
        margin-right: 30px;
      }
    }
    .navigation {
      &__language {
        display: flex;
        @media (max-width: 768px) {
          margin-left: auto;
          margin-right: 14%;
        }
      }
      &__content {
        &-num {
          @include adaptivWidthLg(240, 200);
          display: flex;
          @include adaptivHeightlg(40, 32);
          border-radius: 7px;
          background: rgba(14, 14, 44, 0.1);
          @media (max-width: 768px) {
            @include adaptivWidthmd(250, 200);
            @include adaptivHeightmd(40, 26);
          }
        }
        &-language {
          @include adaptivPaddinglg(5, 5, 5, 5, 3, 3, 3, 3);
          border-right: 1px solid rgba(14, 14, 44, 0.2);
          @include adaptivHeightlg(31, 30);
          display: flex;
          justify-content: center;
          align-items: center;
          &:last-child {
            border-right: 0;
          }
          @media (max-width: 768px) {
            @include adaptiv-fontmd(15, 10);
            @include adaptiv-lineHeightmd(15, 10);
            @include adaptivPaddingmd(5, 5, 5, 5, 3, 3, 3, 3);
            @include adaptivHeightmd(33, 20);
          }
          button {
            background: none;
          }
          .ru,
          .uz,
          .en {
            width: 68px;
            @include adaptivWidthLg(68, 55);
            @include adaptivHeightlg(31, 25);
            @include adaptiv-fontlg(15, 12);
            @include adaptiv-lineHeightlg(15, 12);
            font-family: "Montserrat-bold";
            display: flex;
            justify-content: center;
            align-items: center;
            color: #fff;
            background: transparent;
            @media (max-width: 768px) {
              @include adaptivWidthmd(150, 35);
              @include adaptivHeightmd(33, 20);
              @include adaptiv-fontmd(15, 10);
              @include adaptiv-lineHeightmd(15, 10);
            }
          }
          .choose {
            color: rgba(255, 255, 255, 0.6);
            &.active {
              background: #fff;
              border-radius: 7px;
              color: #93157d;
              font-family: "Montserrat-bold";
            }
          }
        }
      }
    }
  }
  .navigation-list-mobile {
    display: none;
    @media (max-width: 768px) {
      &.active {
        display: block;
        width: 100%;
        height: 100vh;
        background: url("../assets/images/projects/menu.webp");
        background-size: cover;
        background-position: left;
        position: absolute;
        right: 0;
        top: 100%;
        overflow: auto;
      }
      &-list {
        width: 100%;
        height: 80%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: -80px;
        .navigation-list-btn {
          @include adaptivPaddingmd(80, 80, 30, 30, 40, 40, 20, 20);
          width: 100%;
          @include adaptiv-fontmd(30, 16);
          font-family: "Montserrat-semibold";
          letter-spacing: 2px;
          color: #fff;
          background: none;
          align-items: flex-start;
          display: flex;
          text-transform: uppercase;
          &:hover {
            background: #92147c;
          }
        }
        &-link {
          color: #fff;
          font-family: "Montserrat-semibold";
          @include adaptiv-fontmd(30, 16);
          @include adaptiv-lineHeightmd(30, 16);
          @include adaptivPaddingmd(80, 80, 0, 0, 40, 40, 0, 0);
          &-icon {
            color: #2fdef6;
          }
        }
      }
    }
  }
  .menu-burger {
    display: none;
    position: absolute;
    top: 34%;
    right: 10%;
    transform: translate(50%, -49%);
    @include adaptivWidthmd(33, 27);
    width: 100%;
    height: 50px;
    background: transparent;
    overflow: hidden;
    @media (max-width: 768px) {
      display: block;
      &-line {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        &:first-child {
          margin-top: 20px;
          height: 2px;
          display: flex;
          background: #fff;
          position: absolute;
          left: 0;
          transform: rotate(0);
        }
        &:nth-child(2) {
          margin-top: 10px;
          height: 2px;
          display: flex;
          background: #fff;
          position: absolute;
          left: 0;
          transform: rotate(0);
        }
        &:last-of-type {
          margin-bottom: 0;
          height: 2px;
          display: flex;
          background: #fff;
          position: absolute;
          left: 0;
          transform: rotate(0);
        }
      }
      &.open {
        display: block;
      }
      &.close {
        .menu-burger-line {
          &:first-child {
            display: none;
          }
          &:nth-child(2) {
            transform: rotate(40deg);
            width: 30px;
          }
          &:last-of-type {
            transform: rotate(-40deg);
            margin-top: 10px;
            width: 30px;
          }
        }
      }
    }
  }
}
</style>