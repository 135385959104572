<template>
  <Transition name="slide-fade">
    <div v-if="show" class="modal" @click.stop="closeModal">
      <div class="modal__content" @click.stop>
        <span class="modal__close" @click="closeModal" v-if="!submitted"
          >×</span
        >
        <template v-if="!submitted">
          <h6 class="modal__content-title">{{ title[activeLanguage] }}</h6>
          <p class="modal__content-descr" v-html="descr[activeLanguage]"></p>
          <div class="modal__content-form">
            <form
              id="reg-course"
              class="modal__form"
              @submit.prevent
              @submit="sendFormData"
            >
              <input
                type="text"
                :placeholder="placeholder[activeLanguage]"
                name="modal-name"
                class="form__input crm-form__input"
                required
                autocomplete="off"
                v-model="modalName"
              />
              <input
                type="tel"
                :placeholder="placeholder1[activeLanguage]"
                name="modal-tel"
                class="form__input crm-form__input hidden__phone"
                maxlength="15"
                v-model="modalPhone"
                @input="inputNumber"
                @focus="focusNumber"
               
                required
              />
              <input
                type="text"
                placeholder="Telegram @username"
                name="modal-name"
                class="form__input crm-form__input hidden__phone"
                v-model="modalUserName"
              />
              <button class="modal-btn">{{ btn[activeLanguage] }}</button>
            </form>
          </div>
        </template>
        <template v-else>
          <div class="close-modal">
            <div class="modal__content-title">{{ accept[activeLanguage] }}</div>
            <div
              class="modal__content-descr"
              v-html="acceptDescr[activeLanguage]"
            ></div>
          </div>
        </template>
      </div>
    </div>
  </Transition>
</template>

<script>
export default {
  components: {},
  props: {
    show: Boolean,
  },
  data() {
    return {
      submitted: false,
      modalName: "",
      modalPhone: "",
      modalUserName: "",
      title: { ru: "Мы на связи!", uz: `BIZ ALOQADAMIZ!`, en: "we're in touch!" },
      descr: {
        ru: "оставьте заявку и наши менеджера свяжутся <br> с вами в ближайшее время",
        uz: `So'rovoma qoldiring va bizning menedjerlarimiz siz bilan <br> tez orada bog'lanishadi`,
        en: "Our manager will process your <br> application and contact you shortly",
      },
      placeholder: { ru: "Ваше имя", uz: `Ismingiz`, en: "Name" },
      placeholder1: { ru: "Номер телефона", uz: `Telefon raqamingiz`, en: "Phone number" },
      btn: { ru: "Оставить заявку", uz: `So'rovnoma yuborish`, en: "send request" },
      accept: {
        ru: "Ваша заявка отправлена!",
        uz: `So'rovnoma qabul qilindi!`,
        en: "Your application has been sent!",
      },
      acceptDescr: {
        ru: "Наши менеджеры свяжутся <br> с вами в ближайшее время",
        uz: `bizning menedjerlarimiz siz bilan <br> tez orada bog'lanishadi`,
        en: "Our manager will process your <br> application and contact you shortly",
      },
    };
  },
  methods: {
    sendFormData() {
      if (
        this.modalName != "" &&
        this.modalPhone != "" &&
        this.modalPhone.length > 9
      ) {
        const formData = new FormData();
        formData.append("name", this.modalName);
        formData.append("username", this.modalUserName);
        formData.append("contact", this.modalPhone);
        this.$store.dispatch("sendForm", formData).then((response) => {
          
          this.submitted = true;
          this.modalName = "";
          this.modalPhone = "";
          this.modalUserName = "";
          setTimeout(() => {
            this.submitted = false;
            this.$emit("close");
          }, 2000);
        });
      }
    },
    inputNumber(event) {
      if (this.modalPhone.length < 2) this.modalPhone = "+";
      this.modalPhone = this.modalPhone.replace(/[^+|^\d|^\s]/, "");
      // const lengths = [4, 7, 11, 14];
      // lengths.forEach((item) => {
      //   if (this.modalPhone.length == item && event.data) {
      //     this.modalPhone += " ";
      //   }
      // });
    },
    focusNumber() {
      if (this.modalPhone == "") this.modalPhone = "+";
    },
    blurNumber() {
      if (this.modalPhone == "+998 ") this.modalPhone = "";
    },
    closeModal() {
      this.modalPhone = "";
      this.$emit("close");
    },
  },
  mounted() {},
  updated() {},
  computed: {
    activeLanguage() {
      return this.$store.getters.getLanguage;
    },
  },
  watch: {},
};
</script>

<style lang="scss">
@import "@/assets/scss/grid/grid";
.slide-fade-enter-active {
  transition: all 0.5s ease-out;
  .modal__content {
    transition: all 0.5s ease-out;
  }
}
.slide-fade-leave-active {
  transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
  .modal__content {
    transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
  }
}
.slide-fade-enter-from,
.slide-fade-leave-to {
  .modal__content {
    transform: translateY(500px);
  }
  opacity: 0;
}

.modal {
  width: 100%;
  height: 100%;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 10;
  background: rgba(0, 0, 0, 0.541);
  .close-modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 100px;
    @include adaptivMarginlg(0, 0, 100, 0, 0, 0, 50, 0);
  }
  &__content {
    @include adaptivWidthLg(542, 346);
    @include adaptivHeightlg(686, 450);
    width: 100%;
    margin: 0 auto;
    @include adaptivPaddinglg(88, 88, 160, 0, 44, 44, 80, 0);
    background: linear-gradient(
      180deg,
      #3732a9 0%,
      #5a2798 100%,
      #771d8a 100%,
      #93157d 100%
    );
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    overflow: hidden;
    top: 0;
    @media (max-width: 768px) {
      @include adaptivWidthmd(760, 310);
      height: 95%;
      @include adaptivPaddingmd(50, 50, 150, 0, 0, 0, 80, 0);
    }
    .modal__close {
      position: absolute;
      top: 1%;
      right: 4%;
      @include adaptiv-fontlg(60, 30);
      font-family: "Montserrat-thin";
      color: #fff;
      cursor: pointer;
      user-select: none;
      @media (max-width: 768px) {
        @include adaptiv-fontmd(85, 45);
        top: 4%;
        right: 8%;
        transform: translate(50%, -50%);
      }
    }

    &-title {
      font-family: "Montserrat-semibold";
      @include adaptiv-fontlg(34, 23);
      @include adaptiv-lineHeightlg(42, 28);
      text-align: center;
      color: #fff;
      text-transform: uppercase;
      @include adaptivMarginlg(0, 0, 0, 30, 0, 0, 0, 17);
      @media (max-width: 768px) {
        @include adaptiv-fontmd(60, 28);
        @include adaptiv-lineHeightmd(60, 28);
        @include adaptivMarginmd(0, 0, 0, 24, 0, 0, 0, 12);
        text-align: center;
      }
    }
    &-descr {
      display: block;
      font-family: "Montserrat-thin";
      @include adaptiv-fontlg(12, 8);
      @include adaptiv-lineHeightlg(16, 12);
      text-align: center;
      color: #fff;
      @include adaptivMarginlg(0, 0, 0, 30, 0, 0, 0, 20);
      text-transform: uppercase;
      @media (max-width: 768px) {
        @include adaptiv-fontmd(20, 12);
        @include adaptiv-lineHeightmd(24, 14);
        @include adaptivMarginmd(0, 0, 0, 73, 10, 10, 0, 33);
        br {
          display: none;
        }
      }
    }
    &-form {
      @include adaptivWidthLg(444, 237);
      width: 100%;
      justify-content: center;
      display: flex;
      flex-direction: column;
      @include adaptivMarginlg(0, 0, 40, 0, 0, 0, 40, 0);
      @media (max-width: 768px) {
        width: 100%;
        @include adaptivWidthmd(600, 280);       
      }
      .modal-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        @include adaptivWidthLg(444, 237);
        width: 100%;
        @include adaptivHeightlg(40, 30);
        background: #2fdef6;
        color: #fff;
        @include adaptiv-fontlg(13, 10);
        @include adaptiv-lineHeightlg(13, 10);
        font-family: "Montserrat-regular";
        text-transform: uppercase;
        margin: 0 auto;
        .button-link {
          color: #303030;
        }
        @media (max-width: 768px) {
          @include adaptivWidthmd(600, 280);
          @include adaptivHeightmd(75, 35);
          @include adaptiv-fontmd(25, 9);
          @include adaptiv-lineHeightmd(25, 9);
        }
      }
      .form__input {
        width: 100%;
        @include adaptivHeightlg(40, 30);
        display: block;
        font-family: "Montserrat-medium";
        font-style: normal;
        @include adaptiv-fontlg(13, 10);
        @include adaptiv-lineHeightlg(13, 10);
        @include adaptivMarginlg(0, 0, 0, 12, 0, 0, 0, 6);
        @include adaptivPaddinglg(25, 25, 0, 0, 10, 10, 0, 0);
        border-radius: 0px;
        background: rgba(238, 237, 237, 0.3);
        color: #fff;
        &::placeholder {
          color: #fff;
          font-family: "Montserrat-regular";
          font-style: normal;
        }
        @media (max-width: 768px) {
          @include adaptivMarginmd(0, 0, 0, 15, 0, 0, 0, 8);
          @include adaptiv-fontmd(25, 9);
          @include adaptiv-lineHeightmd(25, 9);
          @include adaptivHeightmd(75, 35);
          @include adaptivPaddingmd(15, 15, 0, 0, 10, 10, 0, 0);
        }
      }
    }
  }
}
</style>
