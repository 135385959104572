<template>
  <div class="count" v-intersection="startCount">
    <div class="container">
      <div class="count__content">
        <div
          class="count__content-item"
          v-for="(item, index) in CountItems"
          :key="index"
        >
          <div class="count__content-item-num">
            <template v-if="index != 1">
              <div class="count__content-item-num-span">{{ item.count }}</div>
              <div class="count__content-item-num-span1">
                {{ item.numWord }}
              </div>
            </template>
            <template v-else>
              <div class="count__content-item-num-span1">
                {{ item.numWord }}
              </div>
              &nbsp;
              <div class="count__content-item-num-span">{{ item.count }}</div>
            </template>
          </div>
          <div
            class="count__content-item-descr"
            v-html="item.descr[activeLanguage]"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      animationDuration: 3000,
      CountItems: [
        {
          result: 97,
          count: 0,
          numWord: "%",
          descr: {
            ru: "исследованного рынка <br> Узбекистана",
            uz: "tadqiq etilgan <br> O'zbekiston bozori",
            en: "of the investigated <br> market of Uzbekistan",
          },
        },
        {
          result: 10,
          count: 0,
          numWord: "TOP",
          descr: {
            ru: "успешных маркетинговых проектов <br> с мировыми компаниями ",
            uz: "xalqaro kompaniyalar bilan <br> muvaffaqiyatli loyihalar",
            en: "10 successful marketing projects <br> with global companies ",
          },
        },
        {
          result: 15,
          count: 0,
          numWord: "K+",
          descr: {
            ru: "проанализированных <br> торговых точек",
            uz: "tahlil qilingan <br> savdo nuqtalari",
            en: "analyzed outlets",
          },
        },
      ],
    };
  },
  methods: {
    count(item) {
      setTimeout(() => {
        if (item.count < item.result) {
          item.count += 1;
          this.count(item);
        }
      }, this.animationDuration / item.result);
    },
    startCount() {
      this.CountItems.forEach((item) => {
        this.count(item);
      });
    },
  },
  computed: {
    activeLanguage() {
      return this.$store.getters.getLanguage;
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/grid/grid";
.count {
  max-width: 1920px;
  width: 100%;
  margin: 0 auto;
  background: #3b2c4e;
  @include adaptivPaddinglg(0, 0, 100, 100, 0, 0, 50, 50);
  position: relative;
  &__content {
    width: 100%;
    display: flex;
    justify-content: center;
    @media (max-width: 768px) {
      flex-direction: column;
      align-items: center;
    }
    &-item {
      width: 27%;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      align-items: center;
      border-right: 2px solid #ffffff;
      @media (max-width: 768px) {
        width: 80%;
        @include adaptivPaddingmd(0, 0, 70, 70, 0, 0, 35, 35);
        border-right: none;
        border-bottom: 2px solid #fff;
        &:last-child {
          border-bottom: none;
        }
      }
      &:last-child {
        border-right: none;
      }
      &-num {
        @include adaptivWidthLg(250, 160);
        display: flex;
        justify-content: center;
        align-items: center;
        @include adaptiv-fontlg(96, 48);
        @include adaptiv-lineHeightlg(96, 48);
        color: #fff;
        font-family: "Montserrat-medium";
        // @media (min-width: 1921px) {
        //   font-size: 100px;
        //   line-height: 100px;
        // }
        @media (max-width: 768px) {
          @include adaptiv-fontmd(96, 48);
          @include adaptiv-lineHeightmd(96, 48);
        }

        &-span {
          text-align: right;
          @include adaptiv-fontlg(96, 48);
          @include adaptiv-lineHeightlg(96, 48);
          // @media (min-width: 1921px) {
          //   font-size: 100px;
          //   line-height: 100px;
          // }
          @media (max-width: 768px) {
            @include adaptiv-fontmd(96, 48);
            @include adaptiv-lineHeightmd(96, 48);
          }
        }
      }
      &-descr {
        @include adaptiv-fontlg(20, 15);
        @include adaptiv-lineHeightlg(24, 19);
        color: #fff;
        font-family: "Montserrat-thin";
        text-align: center;
        @media (max-width: 768px) {
          @include adaptiv-fontmd(25, 15);
          @include adaptiv-lineHeightmd(32, 19);
          br{
            display: none;
          }
        }
      }
    }
  }
}
</style>