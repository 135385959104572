import { createRouter, createWebHistory, RouterView } from "vue-router";
import Home from "../views/Home.vue";
import Page404 from "../views/Page404.vue";
import store from "@/store/index";
import setMetas from "@/seo/index";

const child = [
  {
    path: "/:lang(ru|uz|en)?/",
    name: "home",
    component: Home,
    alias: ["/:lang(ru|uz|en)?/", "/:lang(ru|uz|en)?/home"],
  },
];
const routes = [
  {
    path: "/:lang(ru|uz|en)?",
    name: "main",
    component: RouterView,
    children: child,
  },
  // {
  //   path: '/puzzle/:lang(ru|uz|en)?',
  //   name: 'main',
  //   component: RouterView,
  //   children: child
  // },
  {
    path: "/:pathMatch(.*)*",
    name: "error",
    component: Page404,
  },
];
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});
router.beforeEach((to) => {
  const lang = to.params?.lang == "" ? "ru" : to.params?.lang;
  document.documentElement.setAttribute("lang", lang);
  if (lang) {
    store.dispatch("selectLanguage", lang);
  }
  setMetas(to);
});
export default router;
